@import '~antd/dist/antd.less';

body {
  background: #F0F2F5;
}
.ant-layout-header,
.ant-layout-sider {
  background: #fff;
}
.trigger {
  margin-right: 8px;
}
.desktop-menu {
  display: block;
}
.mobile-menu {
  display: none;
}
.logo {
  width: 178px;
  height: 44px;
  background-image: url(./static/media/logo-inovyo.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 32px 10px 0;
  float: left;
}
.site-layout .site-layout-background {
  background: #fff;
}
.space-align-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.space-align-block {
  width: 320px;
  height: 400px;
  text-align: center;
}
.space-align-block .logo {
  margin: 8px auto;
  float: inherit;
}
.space-align-block .ant-form-horizontal .ant-form-item-control {
  margin: 0 auto;
}
.ant-input-group-addon {
  min-width: 90px;
}
.ant-card-meta-title {
  min-height: 50px;
}
.ant-card-meta-avatar {
  margin-top: 7px;
}
.bewImg > img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.app-options {
  position: absolute;
  right: 10px;
  top: 0;
  background: transparent;
}
.app-options > li:first-child {
  padding: 0 !important;
}

.app-options.ant-menu-horizontal>.ant-menu-item,
.app-options.ant-menu-horizontal>.ant-menu-submenu,
.app-options.ant-menu-horizontal {
  border: none;
  border-bottom-color: transparent;
}
.app-options.ant-menu-horizontal .ant-menu-submenu-title {
  padding-right: 0;
  border-bottom-color: transparent;
  padding: 0 5px;
}

.google-login-inovyo {
  width: 100%;
}

.google-login-inovyo > a {
  display: block;
  height: 40px;
  width: 100%;
  background-image: url("./static/media/logo-google-signin.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 50px 10px;
  border-radius: 3px;
  line-height: 40px;
  color: #5c5c5c;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.google-login-inovyo > a:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 3px;
}

@media only screen and (max-width: 768px) {
  .ant-layout-header {
    padding: 0 22px;
  }
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
}

@supports (display: -webkit-box) {
  .ant-card-meta-title {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
}
@primary-color: #9A2376;@link-color: #9A2376;