#page-create-board {
    width: 100%;
    display: block;
}

#page-create-board .divisor {
    width: 100%;
    display: block;
}

#page-create-board  .align-center{
    text-align: center;
}

#page-create-board  .page-box {
    width: 100%;
    display: block;
    border: solid 2px #aaa;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px;
    margin-bottom: 10px;
}

#page-create-board  .page-box > .title-page {
    width: 100%;
    display: block;
    padding: 5px;
}

#page-create-board  .page-box > .close-page{
    position: relative;
    float: right;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: red;
    color: white
}